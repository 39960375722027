// Libraries
import styled from 'styled-components'

// Custom
import IntroMobileBackground from 'images/images/projectsIntroBackgroundMobile.png'
import IntroDeskBackground from 'images/images/projectsIntroBackgroundDesk.png'

export const IntroSection = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.blueDarkerLight};
  background-image: url(${IntroMobileBackground});
  background-repeat: no-repeat;
  background-size: 100%;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktopMin}px) {
    background-image: url(${IntroDeskBackground});
  }
`

export const TitlesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 90px 0px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktopMin}px) {
    padding: 148px 0px;
  }
`

export const IntroTitle = styled.h1`
  margin: 0px 0px 24px 0px;
  font-family: ${({ theme }) => theme.fontFamily.lato};
  font-weight: ${({ theme }) => theme.fontWeight.extraBold};
  font-size: ${({ theme }) => theme.fontSize.h5};
  color: ${({ theme }) => theme.colors.white00};
  line-height: 33.6px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktopMin}px) {
    font-size: ${({ theme }) => theme.fontSize.h1};
    line-height: 67px;
  }
`

export const IntroText = styled.p`
  margin: 0px;
  text-align: center;
  font-family: ${({ theme }) => theme.fontFamily.inter};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  font-size: ${({ theme }) => theme.fontSize.xsmall};
  color: ${({ theme }) => theme.colors.white00};
  line-height: 21px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktopMin}px) {
    max-width: 445px;
    font-size: ${({ theme }) => theme.fontSize.p};
    line-height: 27px;
  }
`

export const ProjectSection = styled.div`
  padding: 0px 0px 64px 0px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktopMin}px) {
    padding: 167px 0px 64px 0px;
  }
`

export const ContactUs = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  padding: 88px 0px 137px 0px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktopMin}px) {
    padding: 80px 0px 157px 0px;
  }
`

export const ContactUsTitle = styled.h1`
  margin: 0px;
  text-align: center;
  font-family: ${({ theme }) => theme.fontFamily.lato};
  font-weight: ${({ theme }) => theme.fontWeight.extraBold};
  font-size: ${({ theme }) => theme.fontSize.h5};
  color: ${({ theme }) => theme.colors.blueDarkerLight};
  line-height: 28px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktopMin}px) {
    font-size: ${({ theme }) => theme.fontSize.h2};
  }
`

export const ContactUsText = styled.h2`
  margin: 24px 0px 32px 0px;
  text-align: center;
  font-family: ${({ theme }) => theme.fontFamily.inter};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  font-size: ${({ theme }) => theme.fontSize.xsmall};
  color: ${({ theme }) => theme.colors.blueDarkerLighter};
  line-height: 21px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktopMin}px) {
    margin: 40px 0px 67px 0px;
    font-size: ${({ theme }) => theme.fontSize.p};
  }
`
