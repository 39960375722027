// Libraries
import styled from 'styled-components'

// Custom
import { PopProjects } from 'utils/animations'

type imageProps = {
  margin?: string
}

export const ProjectSection = styled.div`
  padding: 0px 0px 64px 0px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktopMin}px) {
    padding: 167px 0px 64px 0px;
  }
`

export const ProjectTitlesContainer = styled.div`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktopMin}px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
`

export const ProjectsImageBackground = styled.img`
  max-width: 311px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktopMin}px) {
    max-width: 592px;
  }
`

export const ProjectsImage = styled.img<imageProps>`
  width: 310px;
  position: absolute;
  z-index: 2;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktopMin}px) {
    width: 100%;
    margin: ${({ margin }) => margin || 0};
    opacity: 0;
    animation: ${PopProjects} 1s ease-in-out forwards;
  }
`

export const ProjectContainer = styled.div<imageProps>`
  display: flex;
  flex-direction: column;
  :nth-child(even) {
    ${ProjectsImageBackground} {
      transform: scaleX(-1);
    }
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktopMin}px) {
    flex-direction: row;
    margin: 15vh 0px;
    height: 40vh;
    align-items: center;
    :nth-child(odd) {
      flex-direction: row-reverse;
      ${ProjectTitlesContainer} {
        margin-left: 100px;
      }
    }
  }
`

export const ProjectTitle = styled.h1`
  margin: 129px 0px 16px 0px;
  text-align: center;
  font-family: ${({ theme }) => theme.fontFamily.lato};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.fontSize.h5};
  color: ${({ theme }) => theme.colors.blueDarkerDefault};
  line-height: 28px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktopMin}px) {
    font-size: ${({ theme }) => theme.fontSize.h4};
  }
`

export const ProjectText = styled.p`
  margin: 0px 0px 48px 0px;
  text-align: center;
  font-family: ${({ theme }) => theme.fontFamily.inter};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  font-size: ${({ theme }) => theme.fontSize.xsmall};
  color: ${({ theme }) => theme.colors.blueDarkerLighter};
  line-height: 21px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktopMin}px) {
    max-width: 420px;
    text-align: left;
    font-size: ${({ theme }) => theme.fontSize.p};
  }
`

export const ProjectsImageContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktopMin}px) {
    width: 100%;
  }
`
