// React
import { FC, useState } from 'react'

// Libraries
import { graphql } from 'gatsby'
import { useTranslation } from 'react-i18next'

// Custom
import {
  ContactUs,
  ContactUsTitle,
  ContactUsText,
  IntroSection,
  ProjectSection,
  TitlesContainer,
  IntroTitle,
  IntroText,
} from './styled'
import { MainButtonLink } from 'components/UI/Buttons'
import { PaddingContainer, SimpleContainer } from 'components/UI/Containers'
import { theme } from 'lib/styled-components/theme'
import { GENERAL, SEO, PROJECTS as PROJECTS_TRANSLATIONS } from 'locales'
import Project from 'components/Project'
import SeoElement from 'components/SeoElement'
import WrapProvider from 'WrapProvider'
import useMediaQuery from 'hooks/useMediaQuery'

// Assets
import BapstitellaDesk from 'images/images/projectBapstitellaDesk.png'
import BapstitellaMobile from 'images/images/projectBapstitellaMobile.png'
import DWS from 'images/images/projectDWS.png'
import Guapo from 'images/images/projectGuapo.png'
import Iaris from 'images/images/projectIaris.png'

const PROJECTS = [
  {
    title: 'GUAPO',
    text: PROJECTS_TRANSLATIONS.guapo,
    image: { desk: Guapo, mobile: Guapo },
    margin: '0px',
  },
  {
    title: 'IARIS',
    text: PROJECTS_TRANSLATIONS.iaris,
    image: { desk: Iaris, mobile: Iaris },
    margin: '0px',
  },
  {
    title: 'BAPSTITELLA',
    text: PROJECTS_TRANSLATIONS.baptistella,
    image: { desk: BapstitellaDesk, mobile: BapstitellaMobile },
    margin: '0px 0px 0px -35px',
  },
  {
    title: 'DWS',
    text: PROJECTS_TRANSLATIONS.dws,
    image: { desk: DWS, mobile: DWS },
    margin: '0px -50px 0px 0px',
  },
]

const Projects: FC = () => {
  const { t } = useTranslation()
  const isDesktop = useMediaQuery(
    `(min-width: ${theme.breakpoints.desktopMin}px)`,
  )
  const [active, setActive] = useState(PROJECTS.length + 1)

  const renderProjects = PROJECTS?.map((project, key) => {
    const onActivate = () => {
      setActive(key)
    }

    return (
      <Project
        active={active === key}
        image={project.image}
        isDesktop={isDesktop}
        key={project.title}
        margin={project.margin}
        text={t(project.text)}
        title={t(project.title)}
        setActive={onActivate}
      />
    )
  })

  return (
    <WrapProvider>
      <SeoElement
        title={SEO.projects.title}
        description={SEO.projects.description}
      />
      <SimpleContainer>
        <IntroSection>
          <PaddingContainer>
            <TitlesContainer>
              <IntroTitle>{t(PROJECTS_TRANSLATIONS.title)}</IntroTitle>
              <IntroText>{t(PROJECTS_TRANSLATIONS.subtitle)}</IntroText>
            </TitlesContainer>
          </PaddingContainer>
        </IntroSection>
        <ProjectSection>
          <PaddingContainer flex="column">{renderProjects}</PaddingContainer>
        </ProjectSection>
        <PaddingContainer>
          <ContactUs>
            <ContactUsTitle>
              {t(PROJECTS_TRANSLATIONS.bottomTitle)}
            </ContactUsTitle>
            <ContactUsText>
              {t(PROJECTS_TRANSLATIONS.bottomSubtitle)}
            </ContactUsText>
            <MainButtonLink to="/contact">
              {t(GENERAL.buttons.contact)}
            </MainButtonLink>
          </ContactUs>
        </PaddingContainer>
      </SimpleContainer>
    </WrapProvider>
  )
}

export default Projects

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
