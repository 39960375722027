import { FC } from 'react'
import { Helmet } from 'react-helmet'

import MinimalPrimary from 'images/logos/minimalLogoPrimary.png'
import { useTranslation } from 'react-i18next'

type Props = {
  description: string
  title: string
  image?: string
  favicon?: string
}

const SeoElement: FC<Props> = ({ description, favicon, image, title }) => {
  const {t} =useTranslation()
  return (
  <Helmet>
    <link rel="icon" type="image/png" href={favicon || MinimalPrimary}></link>
    {/* <!-- Primary Meta Tags --> */}
    <title>{t(title)}</title>
    <meta name="title" content={t(title)} />
    <meta name="description" content={t(description)} />

    {/* <!-- Open Graph / Facebook --> */}
    <meta property="og:type" content="website" />
    <meta property="og:url" content="https://heeds.tech/" />
    <meta property="og:title" content={t(title)} />
    <meta property="og:description" content={t(description)} />
    {image && <meta property="og:image" content={image} />}

    {/* <!-- Twitter --> */}
    <meta property="twitter:card" content="summary_large_image" />
    <meta property="twitter:url" content="https://heeds.tech/" />
    <meta property="twitter:title" content={t(title)} />
    <meta property="twitter:description" content={t(description)} />
    {image && <meta property="twitter:image" content={image} />}
  </Helmet>
)}

export default SeoElement
