import { FC, useEffect, useRef } from 'react'

// Components
import {
  ProjectContainer,
  ProjectsImage,
  ProjectsImageBackground,
  ProjectsImageContainer,
  ProjectText,
  ProjectTitle,
  ProjectTitlesContainer,
} from './styled'
import ImageBackgroundMobile from 'images/images/imageBackgroundProjectMobile.png'
import ImageBackgroundDesk from 'images/images/imageBackgroundProjectDesk.png'

type Props = {
  active: boolean
  image: {
    desk: string
    mobile: string
  }
  isDesktop: boolean
  margin: string
  setActive: () => void
  title: string
  text: string
}

const Project: FC<Props> = (props: Props) => {
  const { active, image, isDesktop, margin, setActive, title, text } = props
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const objectRef = ref.current
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActive()
          }
        })
      },
      { threshold: 1 },
    )
    if (objectRef) observer.observe(objectRef)

    return () => {
      if (objectRef) observer.unobserve(objectRef)
    }
  }, [setActive])

  return (
    <ProjectContainer ref={ref}>
      <ProjectTitlesContainer>
        <ProjectTitle>{title}</ProjectTitle>
        <ProjectText>{text}</ProjectText>
      </ProjectTitlesContainer>
      <ProjectsImageContainer>
        {active && isDesktop && (
          <ProjectsImage data-testid="image" margin={margin} src={image.desk} />
        )}
        {!isDesktop && <ProjectsImage margin={'0px'} src={image.mobile} />}
        <ProjectsImageBackground
          src={isDesktop ? ImageBackgroundDesk : ImageBackgroundMobile}
        />
      </ProjectsImageContainer>
    </ProjectContainer>
  )
}

export default Project
